import React from "react";
// import { Route, Switch } from "react-router-dom";
// eslint-disable-line import/no-named-as-default
// import HomePage from "../home/HomePage";
// import Security from "../content/Skills/Security";
// import MarketPlace from "../content/Skills/Marketplace";
// import SkillsPage from "../content/Skills/SkillsPage";
// import ExpertProfile from "../content/ExpertProfile/ExpertProfile";
// import ProfileCreation from "../ProfileCreation/ProfileCreation";
import Header from "../header/Header";
// import HowDoesItWork from "../HowDoesItWork/HowDoesItWork";
// import AboutUs from "../content/AboutUs/AboutUs";
// import PostProblem from "../business/businessPost/PostProblem";
// import BusinessDashboardOld from "../business/businessDashboard/BusinessDashboard";
// import Consultant from "../consultant/Consultant";
import Footer from "../content/Footer/Footer";
// import Profile from "../consultant/Profile";
// import CreateBlog from "../blogs/CreateBlog";
// import contact from "../Contact";
import PropTypes from "prop-types";

function LayoutWithHeader({children}) {
  return (
    <>
      <Header />
      <div className="w-100">
        {children}
        {/* <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/create-blog" component={CreateBlog} />
          <Route exact path="/business" component={BusinessDashboardOld} />
          <Route exact path="/business/post/:id" component={PostProblem} />
          <Route exact path="/business/post" component={PostProblem} />
          <Route exact path="/consultant/profile" component={Profile} />
          <Route exact path="/expert/profile/:id" component={ExpertProfile} />
          <Route exact path="/how-does-it-work" component={HowDoesItWork} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/security" component={Security} />
          <Route exact path="/market" component={MarketPlace} />

          <Route exact path="/contactus" component={contact} />

          <Route
            exact
            path="/profile-creation/:step"
            component={ProfileCreation}
          />
          <Route exact path="/consultant" component={Consultant} />
          <Route exact path="/skills" component={SkillsPage} />
        </Switch> */}
      </div>
      <Footer />
    </>
  );
}

export default LayoutWithHeader;
LayoutWithHeader.propTypes = {
  children: PropTypes.element
}