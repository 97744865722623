import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ExpertProfile.css";
import expert1 from "../../../Images/expert1.png";
import PropTypes from "prop-types";

import { getConsultantProfileData } from "../../../api/consulantProfile";
const ExpertProfile = ({ match }) => {
  const [data, setData] = useState({});
  const [languages, setLang] = useState([]);
  const [indust, setIndust] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const caller = async ()=> {
    try {
      setLoading(true);
      await getConsultantProfileData(match.params.id).then(async (data)=>{
        
        console.log("Here : ",data.data[data.data.length-1]);
        setData(data.data[data.data.length-1]);
        var indus = data.data[data.data.length-1].industry.split(",");
      
        var lang = data.data[data.data.length-1].languages.split(",");
        
        var skiller = data.data[data.data.length-1].skills.split(",");
        // console.log(indus,lang,skiller);
        
        skiller = await skiller.filter(val=> val != '');
       
        indus = await indus.filter(val=> val != '');
        lang = await lang.filter(val=> val != '');
        // console.log(indus,lang,skiller);

        setIndust(indus);
        setLang(lang);
        setSkills(skiller);
       
    
      });
     
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }
  

  caller();
  
}, []);

  return (
    <div>
 {!loading ? 

    <div className="expert-profile">
      <div className="heading">{data.introduction}</div>
      <div className="intro">
        <div className="image-part">
          <img src={data.file ? data.file : expert1} alt="Expert Image" />
        </div>
        <div className="description-part">
          {/* <div className="name">{data.introduction}</div> */}
          <div className="subtitle">
          {data.tagline} <br/>
            <span className="exp">{data.linkedin}</span>
          </div>
          <div className="kahani">
            About Consultant
          </div>
          <p>
            {data.role}
          </p>
          {skills.length > 0 ?
          <>
          <p>Skills</p>
          <div className="skills">
      
            {skills.map((skill, ind)=>{
      
              return     <div key={ind} className="box">{skill}</div>
            })}
        
          
          </div>
          </>
          :undefined}
          {indust.length > 0 ?
          <>
          <p>Industries of Intrest</p>
          <div className="skills">
            {indust.map((skill, ind)=>{

              return     <div key={ind} className="box1">{skill}</div>
            })}
        
          
          </div>
            </>
          :undefined}
            {languages.length > 0 ?
          <>
          <p>Languages Known</p>
          <div className="skills">
            {languages.map((skill, ind)=>{
    
              return     <div key={ind} className="box2">{skill}</div>
            })}
        
          
          </div>
          </>
          :undefined}
        </div>
      </div>
      <div className="call_to_action">
        <Link className="btn btn-primary">Hire now</Link>
      </div>
      <div className="experience">
        <div className="work_experience">Work Experience</div>
        <div className="work_experiences">
          <div className="role">
            <div className="Role-heading"> Consultant - {data.responsibilities} <br/><br/> {data.role}</div>
            <div className="role-sub-title">{data.company} {data.work_from} - {data.work_to}</div>
            <div className="list">
              <ul>
             {skills.map((skill,ind)=>{
              return <li key={ind} className="dot">{skill}</li>
             })}
              </ul>
            </div>
          </div>
          
          
        </div>
        <div className="education work_experience">Education</div>
        <div className="role">
          <div className="Role-heading">{data.degree}</div>
          <div className="sub-title">{data.university} : . {data.education_from} - {data.education_to}</div>
        </div>
      </div>
    </div> :<>Loading....</>}
    </div>

  );
};

export default ExpertProfile;
ExpertProfile.propTypes = {
  match : PropTypes.object
}