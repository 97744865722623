import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// eslint-disable-line import/no-named-as-default
import LayoutWithHeader from "./Layout/LayoutWithHeader";
import LayoutWithoutHeader from "./Layout/LayoutWithoutHeader";
// import Security from "./content/Skills/Security";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import ConsultantBlogCreate from "./Dashboard/ConsultantBlogCreate";
import ConsultantProjects from "./Dashboard/ConsultantProjects";
import UploadFile from "./Dashboard/UploadFile";
import AddCompany from "./BusinessDashboard/company/AddCompany";
import BusinessAllProjects from "./BusinessDashboard/BusinessAllProjects";
import InviteExperts from "./BusinessDashboard/company/InviteExperts/InviteExperts";
import CreateBusinessProject from "./BusinessDashboard/company/CreateBusinessProject";
import BusinessDashboardOld from "./business/businessDashboard/BusinessDashboard";
import BusinessChat from "../components/BusinessDashboard/chat/Chat";
import BusinessChatscreen from "../components/BusinessDashboard/chat/ChatScreen";
import BusinessChata from "../components/BusinessDashboard/chat/chatNew";
import ConsulatntBusinessChat from "../components/Dashboard/consultantchat/Chat";
import ConsultantBusinessChatscreen from "../components/Dashboard/consultantchat/ChatScreen";
import CommingSoon from "./commingSoon";
import { API_URL } from "../services/url";
import Register from "./register/Register";
import Login from "./login/Login";
import Blog from "./blogs/blog";
import ProfileCreation from "./ProfileCreation/ProfileCreation";
import Dashboard from "./Dashboard/Dashboard";
import PageNotFound from "./PageNotFound";
import HomePage from "./home/HomePage";
import Security from "./content/Skills/Security";
import MarketPlace from "./content/Skills/Marketplace";
import SkillsPage from "./content/Skills/SkillsPage";
import ExpertProfile from "./content/ExpertProfile/ExpertProfile";
import HowDoesItWork from "./HowDoesItWork/HowDoesItWork";
import AboutUs from "./content/AboutUs/AboutUs";
import PostProblem from "./business/businessPost/PostProblem";
import Consultant from "./consultant/Consultant";
import Profile from "./consultant/Profile";
import CreateBlog from "./blogs/CreateBlog";
import Contact from "./Contact";
import io from "socket.io-client";
import AllBlogs from "./blogs/allblogs";
import ForgotPassword from "./login/ForgotPassword";
var socket = io(API_URL);


function App() {
  useEffect(() => {
    console.log("socket called",socket)
    if (localStorage.getItem("adminid")) {
      socket.emit("connection");
    }
  },[])
  console.log("here : ", localStorage.getItem("person"))
  return (
    <>
      <div className="w-100">
        <Switch>
          <Route exact path="/" >
            <LayoutWithHeader>
              <HomePage />
            </LayoutWithHeader>
          </Route >
          <Route exact path="/register/:step" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route
            path="/login/forgot-password/:step"
            component={ForgotPassword}
          />
          <Route exact path="/blogs"  >
<AllBlogs />
          </Route>
          <Route exact path="/comingsoon" component={CommingSoon} />
          <Route exact path="/blogs/:blogName" component={Blog} />
          <Route exact path="/create-blog" >
            <LayoutWithHeader>
              <CreateBlog/>
            </LayoutWithHeader>
          </Route>
          <Route exact path="/business" >
            <LayoutWithHeader>
              <BusinessDashboardOld />
            </LayoutWithHeader>
          </Route>
          <Route exact path="/business/post/:id" >
            <LayoutWithHeader>
              <PostProblem />
            </LayoutWithHeader>
          </Route>
          <Route exact path="/business/post" >
             <LayoutWithHeader>
              <PostProblem />
            </LayoutWithHeader></Route>
          <Route exact path="/consultant/profile" >
          <LayoutWithHeader>
              <Profile />
            </LayoutWithHeader></Route>
          <Route exact path="/expert/profile/:id" >
            
            <LayoutWithHeader>
              <ExpertProfile />
            </LayoutWithHeader></Route>
          <Route exact path="/how-does-it-work" >
          <LayoutWithHeader>
              <HowDoesItWork />
            </LayoutWithHeader>
          </Route>
          <Route exact path="/about-us" >
          <LayoutWithHeader>
              <AboutUs />
            </LayoutWithHeader>
          </Route>
          <Route exact path="/dashboard" component={Dashboard } />
          <Route exact path="/contactus" >
            <LayoutWithHeader>
              <Contact/>
            </LayoutWithHeader>
          </Route>
          <Route exact path="/security" component={LayoutWithHeader} >
            <LayoutWithHeader>
              <Security />
            </LayoutWithHeader>
          </Route>
          <Route exact path="/market"  >
            <LayoutWithHeader>

          <MarketPlace />
            </LayoutWithHeader>
          </Route>

          {/* <Route exact path="/business/dashboard" component={tasting} /> */}
          <Route
            exact
            path="/business/dashboard/add-company"
            component={AddCompany }
          />
          <Route
            exact
            path="/business/dashboard/projects"
            component={BusinessAllProjects }
          />
          <Route
            exact
            path="/business/dashboard/createprojects"
            component={CreateBusinessProject }
          />
          <Route
            exact
            path="/business/dashboard/createprojects/:id"
            component={CreateBusinessProject }
          />
          <Route
            exact
            path="/business/dashboard/invite-experts/:id"
            component={InviteExperts }
          />
          <Route
            exact
            path="/consultant/projects"
            component={ConsultantProjects }
          />
          <Route
            exact
            path="/business/dashboard/chat"
            component={BusinessChat}
          />
          <Route
            exact
            path="/business/dashboard/chatscreen/:id"
            component={BusinessChatscreen}
          />
          <Route
            exact
            path="/business/dashboard/chatNew/:id/:proj"
            component={BusinessChata}
          />
          <Route
            exact
            path="/dashboard/chat"
            component={ConsulatntBusinessChat}
          />
          <Route
            exact
            path="/dashboard/chatscreen/:id"
            component={ConsultantBusinessChatscreen}
          />

          <Route
            exact
            path="/dashboard/create"
            component={ConsultantBlogCreate}
          />
          <Route exact path="/dashboard/file" component={UploadFile} />
          <Route
            exact
            path="/profile-creation/:step"
            component={ProfileCreation}
          />
          <Route exact path="/consultant" >
            <LayoutWithHeader>
              <Consultant />
            </LayoutWithHeader>
          </Route>
          <Route exact path="/skills" >
            <LayoutWithHeader>
              <SkillsPage />
              {/* <Consultant /> */}
               
            </LayoutWithHeader>
          </Route>
          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <Route exact path="/oldBus" comoponent={BusinessDashboardOld} />
          {/* <Route path="/try" component={LinkedInPage} /> */}
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </>
  );
}

export default App;
