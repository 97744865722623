import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../services/url";
import jwt from "jwt-decode";
import io from "socket.io-client";
var socket, selectedChatCompare;

const baseUrl = API_URL;
socket = io(baseUrl);
import PropTypes from "prop-types";
const ChatList = ({ chatList }) => {
  const [newChat, setNewChat] = useState(-10);

  const [projectDetails, setProjectDetails] = useState([]);
  console.log("imp", chatList);
  useEffect(() => {
    socket.emit("setup", jwt(localStorage.getItem("token")).data);
    socket.on("connected", () => {
      console.log("socket connected");
    });

    // eslint-disable-next-line
  }, [socket]);
  useEffect(() => {
    console.log("checking socket ",socket);
    if (socket) {
      console.log("after socket !!!", selectedChatCompare);

    socket.on("message recieved", (newMessageRecieved,inv_con_id) => {
      console.log("new",inv_con_id,chatList.inv_con_id)
      setNewChat(inv_con_id);
    });
  }
  },[socket]);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem("consultant_token");
        const consultant_id = jwt(localStorage.getItem("consultant_token")).data
        .consultant_id;
        console.log("consult at chatlist ",consultant_id);
        const res = await axios.get(
          baseUrl + "/api/consultant/projectappliedbyconsultant/" + consultant_id,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(res);
        // setState(res.data.data);
        console.log("projects ", res.data.data);

        setProjectDetails(res.data.data);
        // setExperts(res.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchProject();
  }, []);
  console.log("prod",chatList);

  return (
    <div
      id={"cl_"}
      className="chat_list"
   
    >
      <div className="chat_people"> 
      {chatList.new==1 || newChat==chatList.inv_con_id? <div className="green-dot"></div> : undefined
      }
      

        <div className="chat_img">
          {" "}

          <img
            src="https://ptetutorials.com/images/user-profile.png"
            alt="avatar"
          />{" "}
        </div>
        <div className="chat_ib">
          <h3>{chatList.name}</h3>
          {projectDetails.map((list, key) => (
            chatList.project_id===list.project_id?  <p key={key}>{list.title}</p> : undefined
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatList;
ChatList.propTypes = {
  chatList : PropTypes.object
}