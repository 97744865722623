import React from "react";
import "./About.css";
export default function About() {
  return (
    <div className="about">
      <div className="heading">About us</div>
      <div className="desc">
        <p className="green">We are community of expert consultants</p>
        <p>


          Wenester is a micro consulting platform for businesses and startups to onboard best talent as consultants to get growth through either solving a burning problem or getting a new initiative to strategize better.  We wish to enable you to come out of the crisis with advice and consultation from best professionals for no cost for 5 hours and post that you will need to pay as per terms agreed before the start of consultation. Based on our proven algorithm and extensive vetting process, we will find the right consultant for any engagement. This includes all project phases from strategy to implementation and all project types from workshop facilitation to long-term transformation programs.
        </p>
        <div style={{ textAlign: 'left', }}>

          <p style={{ fontWeight: "bold", color: "black" }} >We are committed to 5 unique ways :
          </p>
          <p>
            <span style={{ fontWeight: "bold", color: "black" }}>

              Personal :
            </span>
            We support organizations and independent consultants in any way we can, tailoring our service to the individual.
          </p>
          <p>
            <span style={{ fontWeight: "bold", color: "black" }}>
              Fast :
            </span>
            We are responsive and we always find an immediate solution for you no matter how short the notice.
          </p>
          <p>
            <span style={{ fontWeight: "bold", color: "black" }}>

              Vetted :</span> Our extensive candidate selection process is driven by tech and human interaction.
          </p><p>
            <span style={{ fontWeight: "bold", color: "black" }}>

              Flexible :</span> We help organizations and experts pick and choose the people and projects they want to.
          </p>
          <p>
            <span style={{ fontWeight: "bold", color: "black" }}>

              Tech-enabled :</span> We use technology to continuously improve the quality of our matching and the efficiency of our processes.
          </p>
        </div>

      </div>

    </div>
  );
}
