import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
const Three = ({ step, values, onChange, onSubmit }) => {
  const [working, setWorking] = useState(true);
  const setCurrentDate = async (e)=>{
    console.log(e)
    await setWorking(prev => !prev);
  }
  const validate = (e)=>{
    var err = "";
    if(values.degree == "" ) err = "Enter your Degree";
    if(values.university == "") err = "Enter University";
    if(values.degree_from_month == "") err = "Enter Date";
    if(values.degree_from_year == "") err = "Enter Date";
    console.log("error : ",err);
    if(err) {
      e.preventDefault();
      alert(err);
    }
    else{
      onSubmit();
    }
  }
  return (
    <div className="one">
      <div className="one__header">
        <div className="title">Enter your credentials!</div>
        <span className="bg-green"></span>
        <span className="bg-green"></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="one__form-heading">Education details</div>
      <div className="one__form">
        <div className="one__form__input-field">
          <div className="one__form-label">Degree*</div>
          <input required
            type="text"
            placeholder="Enter your degree"
            name="degree"
            value={values.degree}
            onChange={onChange}
            maxLength={20}

          />
        </div>
        <div className="one__form__input-field">
          <div className="one__form-label">University*</div>
          <input required
            type="text"
            placeholder="Enter your University"
            name="university"
            value={values.university}
            onChange={onChange}
            maxLength={20}

          />
        </div>
        <div className="one__form__input-field date">
          <div className="form__date-from">
            <div className="one__form-label from">From*</div>
            <div className="from">
              <input required
                type="text"
                placeholder="Month"
                name="education_from_month"
                value={values.education_from_month}
                onChange={onChange}
            maxLength={20}

              />
              <input required
                type="text"
                placeholder="Year"
                name="education_from_year"
                value={values.education_from_year}
                onChange={onChange}
            maxLength={20}

              />
            </div>
          </div>
          {working ?
          <div className="form__date-to">
            <div className="one__form-label to">To*</div>
            <div className="to">
              <input required
                type="text"
                placeholder="Month"
                name="education_to_month"
                value={values.education_to_month}
                onChange={onChange}
            maxLength={20}

              />
              <input required
                type="text"
                placeholder="Year"
                name="education_to_year"
                value={values.education_to_year}
                onChange={onChange}
            maxLength={20}

              />
            </div>
          </div>:<></>}

          <div className="one__form-currently-working">
            <input required type="checkbox" 
             onChange={(e)=>{setCurrentDate(e);
              console.log(working);
              console.log(e.target.value)}
            }/>
            <div className="currently-working">Currently working</div>
          </div>
        </div>
        <Link to="#" className="add-education-btn">
          Add Education
        </Link>
      </div>

      <div className="one__footer">
        <Link
          to={`/profile-creation/${parseInt(step, 10) - 1}`}
          className="btn btn-outline footer-btns"
        >
          Back
        </Link>
        <Link
          to={`/profile-creation/${parseInt(step, 10) + 1}`}
          className="btn btn-primary footer-btns"
          onClick={e=>validate(e)}
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default Three;

Three.propTypes = {
  step: PropTypes.string,
  values: PropTypes.object,
  onChange:PropTypes.func,
  onSubmit :PropTypes.func
  

};
