import React from "react";
import TextInput from "../../common/TextInput";
import PropTypes from "prop-types";
import * as filesApi from "../../../api/filesApi";
import Select from "react-select";
export default function BusinessForm({
  edit,
  onSubmit,
  values,
  onChange,
  delFile,
  delUploadedFile,
  domain_types,
  loading,
}) {
  const onValChange = (e, vals) => {
    const newValues = values[vals]
      .map((val) =>
        val.id === e.target.name ? { ...val, value: e.target.value } : val
      )
      .filter((val) => val.value !== "");
    const id = newValues[newValues.length - 1]
      ? newValues[newValues.length - 1].id
      : "0";
    newValues.push({
      id: "" + (parseInt(id) + 1),
      value: "",
    });
    onChange(vals, newValues);
  };
  const ask_question_section = values.questions.map((ques) => (
    <TextInput
      key={ques.id}
      value={ques.value}
      placeholder={"Add Custom Question"}
      name={ques.id}
      required={false}
      onChange={(e) => onValChange(e, "questions")}
    />
  ));
  // const domain = values.domains.map((dom) => (
  //   <TextInput
  //     key={dom.id}
  //     value={dom.value}
  //     placeholder={"Add Domain"}
  //     name={dom.id}
  //     required={false}
  //     onChange={(e) => onValChange(e, "domains")}
  //   />
  // ));
  return (
    <form className="post-form" onSubmit={onSubmit}>
      <div className="form-group">
      <h5 style={{color:"#01c853", textAlign:"left"}}>Add Title</h5>
        <TextInput
        
          value={values.title}
          placeholder={"Add Title..."}
          name="title"
          required={true}
          className="input-title"
          maxLength="100"
          onChange={({ target }) => onChange(target.name, target.value)}
        />
      </div>
      <p>Max length 100</p>
      <h5 style={{color:"#01c853", textAlign:"left"}}>Add Description</h5>
      <div className="input-group">
        
        <textarea
        style={{background:"#F1F1F1"}}
          className="form-control input-description"
          onChange={({ target }) => {
            onChange(target.name, target.value);
          }}
          value={values.description}
          name="description"
          required={true}
          maxLength="200"
          placeholder={"Add Description..."}
        />
      </div>
      <p>Max length 200</p>

      {values.files_to_be_uploaded.length > 0 ? (
        <>
          <h4 className="input-lebal">File Details:</h4>
          {values.files_to_be_uploaded.map((file) => (
            <div
              key={file.name}
              className="d-flex .d-inline-flex justify-content-start align-items-center"
            >
              <div className="p-10">
                <p className="text-left">
                  <small>File Name: {file.name}</small> <br />
                  <small> File Type: {file.type}</small> <br />
                  <small> File Size: {file.size / 1000 / 1000} MB</small>
                </p>
                <p></p>
                <p></p>
              </div>
              <button
                onClick={() => delFile(file.name)}
                className="btn btn-danger btn-sm ml-auto  width-100"
                style={{width:"fit-content", paddingLeft:"20px !important", paddingRight:"20px !important"}}
              >
                Delete
              </button>
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {values.files && values.files.length > 0 ? (
        <>
          <h4>Already Uploaded Files</h4>
          {values.files.map((file) => (
            <div
              key={file.id}
              className="d-flex d-inline-flex justify-content-start align-items-center"
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  filesApi.fetchFile(file.file_url);
                }}
                className="btn btn-link"
              >
                {file.file_url}
              </button>
              <button
                onClick={() => delUploadedFile(file.id)}
                className="btn btn-danger btn-sm ml-auto "
                style={{width:"fit-content", paddingLeft:"20px !important", paddingRight:"20px !important"}}
              >
                Delete
              </button>
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {/* Files input */}
      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          id="validatedCustomFile"
          name="files_to_be_uploaded"
          multiple
          onChange={({ target }) => onChange(target.name, target)}
        />
        <label className="custom-file-label" htmlFor="validatedCustomFile">
          Choose files...
        </label>
        <div className="invalid-feedback small">
          Example invalid custom file feedback
        </div>
        <p className="text-info input-lebal">
          Size of file should be less than 20 MB
        </p>
      </div>
      <p style={{marginTop:"10px"}}>Image for project Profile</p>
      {values.image ? 

      <div
              
              className="d-flex d-inline-flex justify-content-center align-items-center"
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  filesApi.fetchFile(values.image.file_url);
                }}
                className="btn btn-link"
              >
                {values.image.file_url}
              </button>
              
            </div>: ""}



        {values.image_to_be_uploaded ? 
        
        <div
      
        className="d-flex .d-inline-flex justify-content-start align-items-center"
      >
        <div className="p-10">
          <p className="text-left">
            <small>File Name: {values.image_to_be_uploaded[0].name}</small> <br />
            <small> File Type: {values.image_to_be_uploaded[0].type}</small> <br />
            <small> File Size: {values.image_to_be_uploaded[0].size / 1000 / 1000} MB</small>
          </p>
          <p></p>
          <p></p>
        </div>
       
      </div>
        
       : ""}



      <div className="custom-file">
       
      <input
          type="file"
          className="custom-file-input"
          id="validatediCustomFile"
          name="image_to_be_uploaded"
          single
          onChange={({ target }) => {
            
            if(target.files[0].type === "image/png"  || target.files[0].type === "image/jpg" || target.files[0].type === "image/jpeg")    onChange(target.name, target);
                else{
                  alert("Please choose a image only");
                }
          
          }}
        />
        <label className="custom-file-label" htmlFor="validatediCustomFile">
          Choose Project image
        </label>
      </div>

      {/* Questions */}
      <div className="form-group">
        <h5 style={{color:"#01c853", textAlign:"left"}} >Ask Questions</h5>
        {ask_question_section}
      </div>
      <div className="form-group">
        <h5 style={{color:"#01c853", textAlign:"left"}}>Domain of the Project problem</h5>
        <Select
          closeMenuOnSelect={false}
          loadingMessage="loading the options"
          isMulti
          options={domain_types}
          value={values.domains}
          onChange={(values) => {
            onChange("domains", values);
          }}
        />
        <small className="text-info input-lebal">
          select only 5 domains of the projects
        </small>
      </div>
      <button disabled={loading} className="btn btn-dark" type="submit">
        {edit ? "Edit Project" : "Add Project"}
      </button>
    </form>
  );
}
BusinessForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  delFile: PropTypes.func.isRequired,
  delUploadedFile: PropTypes.func.isRequired,
  domain_types: PropTypes.array,
  loading: PropTypes.bool
};
