import React from "react";
import { Link } from "react-router-dom";
import "./blogCard.css";
import PropTypes from "prop-types";

export default function BlogCard({ blog }) {
  return (
    <div className="CardContainer">
      <div className="CardCenter">
       <img className="cardImage" src={blog.file_uploaded} style={{maxWidth:"320px"}} alt="blog image" />
       <div>

        <div className="TitleandDate">
          <div className="CardAuthor">
            <p className="WrittenBy">{blog.writtenby}</p>
          </div>
          <div className="CardDate">
            <p className="PostTime">-{blog.post_time.substring(0, 10)}</p>
          </div>
        </div>

        <div className="CardContent">
          <div className="CardTitleandDesc">
            <div className="CardTitle">
              <h5 className="BlogTitle">{blog.title.slice(0, 50)}...</h5>
            </div>

            <div className="CardDesc">
              <h5 className="BlogContent">{blog.content.slice(0, 100)}...</h5>
            </div>

            <div className="ViewBtn">
              <Link className="ViewLink" to={{ pathname: `/blogs/${blog.blog_id}` }} state={{ blog: blog }}>Read More &#8594;</Link>
            </div>
          </div>
          

        </div>
        </div>


      </div>
    </div>
  );
}
BlogCard.propTypes = {
  blog: PropTypes.object
}