import React from "react";

export default function MarketPlace() {
  return (
    <div style={{ margin: "32px" }}>
      <h1
        className="aus__landing-card-title"
        style={{ fontSize: "40px", marginBottom: "40px" }}
      >
        MARKETPLACE SERVICES{" "}
      </h1>
      <div style={{ marginBottom: "32px" }}>

     

      <span className="aus__landing-card-title" style={{ fontSize: "24px" }}>
        Corporate strategy:{" "}
       </span>{" "}
       <span className="aus__landing-card-desc">
        we help in dealing with issues related to the portfolio mix of
        businesses held by a multi-business organization, such as the rationale
        behind the design of the portfolio as well as performance and returns
        required of the businesses.
       </span>
       </div>
       <div style={{ marginBottom: "32px" }}>
      <span className="aus__landing-card-title" style={{ fontSize: "24px" }}>
        Supply chain:
       </span>{" "}
       <span className="aus__landing-card-desc">
        Our ability to manage supply chains is enhanced by our ability to
        encourage collaboration among the individual businesses, which is
        complemented by our ability to track success and failure throughout the
        entire supply chain.
       </span>
       </div>
       <div style={{ marginBottom: "32px" }}>

      <span className="aus__landing-card-title" style={{ fontSize: "24px" }}>
        Data analytics and business analytics:
       </span>{" "}
       <span className="aus__landing-card-desc">
        using data analytics, we provide organizations with the necessary
        information to implement change. Furthermore, data-driven insights
        support business decisions by revealing patterns, trends, risks, and
        opportunities and analyzing massive datasets to uncover patterns,
        trends, risks, and opportunities.
       </span>
       </div>
      <div style={{ marginBottom: "32px" }}>

      <span className="aus__landing-card-title" style={{ fontSize: "24px" }}>
        Finance and accounting:{" "}
       </span>{" "}
       <span className="aus__landing-card-desc">
        Recording, summarizing, and reporting results from business operations
        over time is the essence of this field.
       </span>
      </div>
      <div style={{ marginBottom: "32px" }}>

      <span className="aus__landing-card-title" style={{ fontSize: "24px" }}>
        Market research:
       </span>{" "}
       <span className="aus__landing-card-desc">
        {" "}
        obtaining customer feedback and opinions on a product or service through
        market research allows an organization to gain a better understanding of
        its target market.
       </span>
      </div>
      <div style={{ marginBottom: "32px" }}>

      <span className="aus__landing-card-title" style={{ fontSize: "24px" }}>
        Technology and digital:{" "}
       </span>{" "}
       <span className="aus__landing-card-desc">
        organizations now have instant access to a wide array of individuals
        beyond the immediate stakeholder group.
       </span>
      </div>
    </div>
  );
}
