import React from 'react'
import PropTypes from "prop-types";

import { NavLink, Link } from "react-router-dom";
import projicon from "../../Images/projicon.png";
import blogicon from "../../Images/blogicon.png";
import chaticon from "../../Images/chat.png";
import usericon from "../../Images/user.png";
import logo from "../../Images/wenester-logo.svg";
export default function Sidebar({setside}) {
  return (
    <div>
              <div className="sidebar">
                <p style={{position: 'absolute', right:"10px", top:"10px", color:"white", cursor:"pointer"}} onClick={()=>setside(false)} >x</p>
        <Link to="/">
          <div
            className="sidebar__logo"
            style={{ backgroundImage: `url(${logo})` }}
          ></div>
        </Link>
        <span className="dash-icon">
        {/* <img src={dashicon} /> */}
        </span>
        <div className="sidebar__heading">Dashboard</div>
        <nav className="sidebar__nav">
          <NavLink to="/blogs" activeClassName="active">
          <span className="dash-icon">
            <img src={blogicon}/>
    </span>
            Blogs
          </NavLink>
          <NavLink
            to="/business/dashboard/add-company"
            activeClassName="active"
          >
                    <span className="dash-icon">

            <img src={usericon}/>
            </span>
            Add Company

          </NavLink>
          <NavLink to="/business/dashboard/projects" activeClassName="active">
          <span className="dash-icon">
         
          <img src={projicon}/>
         </span>   
            Projects
          </NavLink>
          <NavLink to="/business/dashboard/chat" activeClassName="active">
          <span className="dash-icon">
         
          <img src={chaticon}/>
         </span>    
            Chat
          </NavLink>
        </nav>
      </div>
    </div>
  )
}
Sidebar.propTypes = {
  setside: PropTypes.func
}