import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import ExpertCard from "./ExpertCard/ExpertCard";
import "./InviteExperts.css";
import axios from "axios";
import { API_URL } from "../../../../services/url";
// const baseUrl = process.env.API_URL;
const baseUrl = API_URL;
import PropTypes from "prop-types";


const InviteExperts = ({ match }) => {
  console.log("inside invite experts component", match);
  const projectId = match.params.id; // use this to send invite

  const [experts, setExperts] = useState([]);

 
  const [state, setState] = useState([]);

  useEffect(() => {
    const BusinessProjects = async () => {
      console.log("fetching):");
      // setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const res = await axios.get(baseUrl + "/api/business/uninvited", {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        
      
        let art = res.data.data.reverse();
        const filteredArr = art.reduce((acc, current) => {
          const x = acc.find(item => item.consultant_id === current.consultant_id && item.project_id === current.project_id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        for(let i  =0;i< res.data.data.length ; i++){
          
          console.log(res.data.data[i]);
        }
        setState(res.data.data);
        console.log("consultant : ",res.data.data);
        setExperts(filteredArr);
      } catch (error) {
        console.log(error.messagge);
      } finally {
        // setLoading(false);
      }
    };
    BusinessProjects();
  }, [state.length]);
  return (
    <Layout>
      <div className="company-heading text-center mt-2">
        <h3>All Experts</h3>
      
        {experts.length > 0 && (
          <div className="experts">
            {experts.map((expert) => (
              <ExpertCard
                key={expert.consultant_id}
                project_id={projectId}
                consultant_id={expert.consultant_id}
                name={expert.tagline}
                role={expert.role}
                img = {expert.file}
                introduction={expert.introduction}
              />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default InviteExperts;
InviteExperts.propTypes = {
  match : PropTypes.object
}