import React, { useState, useEffect } from "react";
import axios from "axios";
import ApplyedModel from "./models/Applyed";
import AllProjectsCard from "./AllProjectsCard";
import { API_URL } from "../../services/url";
const baseUrl = API_URL;

const AllProjects = () => {
  const [applyedSuccess, setApplyedSuccess] = useState(false);
  const [state, setState] = useState([]);

  const AllProjectsCall = async () => {
    
    try {
      // console.log("first");
      // token is not working here
      const token = localStorage.getItem("consultant_token");
      console.log("token",token);
     axios.get(baseUrl + "/api/consultant/project", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then(async (res)=>{
        const consultant_id = localStorage.getItem("consultant_id");
    
    const data = await axios.get(
      baseUrl +
        `/api/consultant/projectappliedbyconsultant?consultant_id=${consultant_id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("here is data", data, consultant_id);
      let arr = [];
    for (let i = 0;i<res.data.data.length;i++){
      let st = 0;
      for(let j = 0;j<data.data.data.length;j++){
        // console.log(res.data.data[i].project_id, data.data.data[j].project_id);
        if(res.data.data[i].project_id == data.data.data[j].project_id){
          st = 1;
          
          break;
        }
      }
      console.log(st,res.data.data[i] );
      if (st==0) arr.push(res.data.data[i]);
    }
    setState(arr);
      });
      // console.log("hi ", res.data.data);
      
      // console.log("res :",res);
    } catch (error) {
      console.log("error", error);
    } 
  };
  const applyClcik = ()=>{
    AllProjectsCall();
  }
  // console.log("data : ", state);
  useEffect(() => {
   
  AllProjectsCall();
  }, [state.length]);
  const closeApplyed = () => {
    setApplyedSuccess(false);
  };
  return (
    <>
      {applyedSuccess && <ApplyedModel onClose={closeApplyed} />}
    
      {state.map((items) => {
        return (
          <>
            <AllProjectsCard
              className="blog-card"
              project={items}
              setApplyedSuccess={setApplyedSuccess}
              applyClcik={applyClcik}
            />{" "}
          </>
        );

      })}
     
    </>
  );
};

export default AllProjects;
