import React, {useRef,useState} from "react";
import { Link } from "react-router-dom";
import { CONSULTANT, BUSINESS } from "../../constants";

// import { CONSULTANT } from "../../constants";
import logo from "../../Images/wenester-logo.png";
import Sidebar from "../expert_utils/sidebar";
import Sidebar2 from "../businessHelper/sidebar";
function Header() {
    const [side,setside] = useState(false);
  const siderbar = useRef(null);

    const onLogoClick = ()=>{
        console.log(siderbar.current.data)
        setside(prevstate=>!prevstate);
      }
  return (
    <>
     <div ref={siderbar} className={side?"":"hidden"}>

{localStorage.getItem("person")==BUSINESS? <Sidebar2 setside={setside} />:localStorage.getItem("person")==CONSULTANT? <Sidebar setside={setside} />:<></>}
 </div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light" >
    
          {" "}
          <div style={{zIndex:"1000"}}>

          <img src={logo} alt="logo" onClick={onLogoClick} />
          </div>
     

  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" style={{backgroundColor:"#f8f9fa"}} id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
      <Link to="/blogs">Resources</Link>
      </li>
      <li className="nav-item">
      <Link to="/about-us">About us</Link>
      </li>
      
      <li className="nav-item">
      <Link to="/how-does-it-work">How does it work</Link>
      </li>
      <li className="nav-item">
      <Link to="/contactus">Contact us</Link>
      </li>
    </ul>
   
  </div>
</nav>
     
    </>
  );
}

export default Header;

