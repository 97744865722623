import React from "react";
import "./skill.css";
// import { Link, Navigate } from "react-router-dom";
import ExpertCard from "./ExpertCard/ExpertCard";
import { useLocation } from "react-router-dom";

export default function SkillsPage() {
  const location = useLocation();
  const {heading,data, head, subskills} = location;
  console.log("hello",location);
  return (
    <div className="experts-page">
      <div className="heading-main">{heading}</div>
      <div className="paragraph">
        {head}
      </div>
      <div className="categories">
      {subskills.split(",").map((skill)=> (
      <div key={skill} className="category">{skill}</div>)
      )}
        
    
      </div>
      <div className="experts-heading">
        <div className="verified">Verified experts</div>

      </div>
      <div className="expertsDiv">

      {data.map((expert, index) => (
        <div key={index} className="four-experts">
          <ExpertCard
          cons={expert.consultant_id}
            key={expert.profile_id}
            profile_id={expert.profile_id}
            name={expert.company}
            tagline={expert.tagline}
            linkedin={expert.linkedin}
            experience={8}
            image={expert.file}
            />

         </div>
      ))}
      </div>


 
  
    </div>
  )
}
