import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./blog.css";
import { getAllBlogs } from "../../api/blogs";

const Blog = (props) => {
  //   const { title, subtitle, blog_content, image, uploadDate } =
  //     props.location.blog;
  //   const d = new Date(uploadDate);
  const [blog, setBlog] = useState({
    title: "", content: "", writtenby: "", post_time: ""
  });


  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behaviour: "smooth",
      }),
    []
  );
  useEffect(() => {
    try {
      // console.log("props",parseInt(props.location.pathname.substring(7)));
      getAllBlogs().then((res) => {
        res.data = res.data.filter((data) => {
          return data.blog_id == parseInt(props.location.pathname.substring(7));
        })
        setBlog(res.data[0]);
        // console.log("kbhjgfd",res.data);
      }).catch((error) => {
        console.log("error", error)
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(
    () => {
      if (props.location.state) setBlog(props.location.state);
    },
    [props.location.state]
  );
  // console.log(props.location.state);
  const { title, content, writtenby, post_time } = blog;
  return (
    <div className="MainContent">
      <div className="centerContent">
        <div className="nameAndDate">
          <div className="Name">
            <p className="name1">By {writtenby}</p>
          </div>

          <div className="Date">
            <p className="date1">{post_time.substring(0, 10)}</p>
          </div>
        </div>

        <div className="Title d-flex justigy-center align-center flex-col">
          <h1 className="myTitle">{title}</h1>
        </div>

        <div className="Image d-flex justify-content-center align-center">
          <img src={blog.file_uploaded} alt="" />

        </div>
        <p className="smallTitle d-flex justify-content-center align-center">{title}</p>

        <div className="description d-flex flex-column justify-content-center align-center">
     
         {content.split("\n").map((line)=>{
 return (<h5 key={line} className="d-flex justify-content-center align-center"  >{line}</h5>)
          })}
        </div>
      </div>
    </div>


    // <section className="single-blog "  >
    //   <div className="blogLandingImageWrapper">
    //     <img style={{ width: "100%" }} src={blog.file_uploaded} alt="BlogLanding" />
    //   </div>
    //   <div className="posted-blog d-flex justigy-center align-center flex-col" style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    //     <div style={{ maxWidth: '70%' }}>
    //       <div className="d-flex justify-content-center align-center" style={{ alignItems: 'flex-end', justifyContent: 'center', marginBottom: '10px' }}>
    //         <span style={{ fontSize: "32px", marginRight: "10px" }}>{title}</span>
    //         <span style={{ fontStyle: "italic" }}>By {writtenby}</span>
    //       </div>
    //       <h5>{content}</h5>
    //       <h3 className="d-flex" style={{ alignItems: 'center', justifyContent: 'start' }}>{post_time.substring(0, 10)}</h3>
    //     </div>
    //   </div>
    // </section>
  );
};

export default Blog;
Blog.propTypes = {
  location : PropTypes.object
}