import React, { useState } from "react";
// import Layout from "./Layout1";
import "./ConsultantBlogCreate.css";
import axios from "axios";
import { API_URL } from "../../services/url";
import jwt from "jwt-decode";
import Layout from "../Dashboard/Layout";
import {Modal,Button} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
const ConsultantBlogCreate = () => {
  const history = useHistory();
  const [blog, setBlog] = useState({
    title: "",
    description: "",
    content: "",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const handleClose = () => setShow(false);
  const onChange = ({ target }) => {
    setBlog({ ...blog, [target.name]: target.value });
  };
  const onSubmit = async () => {
    setIsDisabled(true);
    try {
    if (blog.title.length == 0 || blog.description.length == 0 || blog.content.length == 0 || selectedFiles.length == 0) {
      console.log("this is bloog", blog, selectedFiles)
      alert("Please fill all details");}
      else {

        let formData = new FormData();
        formData.append('image', selectedFiles[0]);
        formData.append('title', blog.title.replaceAll(`'`, `&apos;`).replaceAll(`"`, `&quot;`));
        formData.append('content', blog.content.replaceAll(`'`, `&apos;`).replaceAll(`"`, `&quot;`));
        formData.append('description', blog.description.toString().replaceAll(`'`, `&apos;`).replaceAll(`"`, `&quot;`));
        formData.append('writtenby', jwt(localStorage.getItem("consultant_token")).data.name);
        console.log("this is bloog", blog, selectedFiles)
        await axios.post(API_URL + "/api/consultant/blog", formData).then((res) => {
          console.log("res :", res);
          if (res.status === 201) {
            setBlog({
              title: "",
              content: "",
            });
            history.push("/dashboard");
          }

        })
      }
    } catch (error) {
      console.error("error", error);
      alert("internal error");
    }
    setIsDisabled(false);
  };
  return (
    <Layout>
       <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you  want to create this blog ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Publish
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="CreateBlogContainer">
        <div className="CreateBlogCenter">
          <div className="CreateBlogHeading">
            <h1 className="CHeading">Share your Story</h1>
          </div>
          <div className="CreateBlogContent">
            <input type="text" className="CreateBlogTitle" placeholder="Title..." name="title" value={blog.title} onChange={onChange} maxLength={200} /><br />
            <p className="CreateBlogLength">Max Length 200</p>
            <input type="text" className="BlogDesc" placeholder="Description..." name="description" value={blog.description} onChange={onChange} maxLength={500} /><br />
            <p className="CreateBlogLength">Max Length 500</p>

            <textarea row={100} col={100} name="content" placeholder="Content" value={blog.content} onChange={onChange} className="BlogContents" maxLength={9800}></textarea><br />
            <p className="CreateBlogLength">Max Length 10000</p>
          
            <label forHtml="fileupload" > <span style={{padding:"10px 25px", background:"#00c853", borderRadius : "8px", color:"white"}}>Upload</span>
            <input type="file" style={{display:"none"}} className="BlogImageU" placeholder="Select any Image" id="fileupload" name="fileupload" onChange={(e) => { 
              // console.log(e.target.files[0].type);
              if(e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg"){
                console.log(e.target.files)
                setSelectedFiles(e.target.files);
              }
              else {
                e.preventDefault();
                alert("PLease select an image file")
              }
            }} />
            <span> {selectedFiles.length > 0 ? selectedFiles[0].name : undefined} </span>
            </label>
            <br />
          </div>
          <div className="BlogBtn">
            <button className="Bbtn" disabled={isDisabled} onClick={()=>setShow(true)}>Publish</button>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default ConsultantBlogCreate;
