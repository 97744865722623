import React from "react";
import { Link } from "react-router-dom";
import "./ExpertCard.css";

import PropTypes from "prop-types";

const ExpertCard = ({ cons,image, name, tagline, linkedin }) => {
  return (
   <Link to={`/expert/profile/${cons}`} style={{width:'100%'}}>
      <article className="expcard expcard--1">
  <div className="expcard__info-hover">
 
     
    
  </div>
  <div className="expcard__img"></div>
  <a href="#" className="expcard_link">
     <div className="expcard__img--hover" style={{backgroundImage: `url(${image ? image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZa0CKGp-TrwZAasTRSxDmjZHRNaUsLlstKZtDVlqJRXh7UPw6DEd3gi5NuJgjICqfSpg&usqp=CAU"})`}}></div>
   </a>
  <div className="expcard__info">
    <span className="expcard__category"> {name}</span>
    <h3 className="expcard__title">{tagline}</h3>
    <span className="expcard__by">{linkedin}</span>
  </div>
</article>
  
     </Link>
  );
};



export default ExpertCard;
ExpertCard.propTypes = {
  cons : PropTypes.string,
  image: PropTypes.string,
  tagline :  PropTypes.string,
  linkedin :  PropTypes.string,
  name: PropTypes.string
}
 
      {/* <div className="expert-expcard">
        <div className="img-container">
          <img src={image ? image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZa0CKGp-TrwZAasTRSxDmjZHRNaUsLlstKZtDVlqJRXh7UPw6DEd3gi5NuJgjICqfSpg&usqp=CAU"} alt="Expert-Image" />
        </div>
        <h3 className="expert-name">{name}</h3>
        <div className="experience">{experience} years of experience</div>
        <div className="description">
          {tagline}
        </div>
        <p style={{fontSize:"12px", wordBreak: "break-all"}}>{linkedin}</p>
      </div> */}