import React, { useState } from "react";
import "./Layout.css";
import Sidebar from "../businessHelper/sidebar";
import logo from "../../Images/wenester-logo.svg";
import { useHistory } from "react-router-dom";
// import Applications from "./Notification/Applications";
import {Modal,Button} from 'react-bootstrap';
import PropTypes from "prop-types";

const Layout = ({ children }) => {
  const history = useHistory();
  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("person");
    history.push("/login");
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const onLogoClick = ()=>{
    
    
  }
  return (
    <div className="layout">
       <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you  want to Logout ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
<Sidebar />

      <div className="layout__main">
        <div className="header">
        <div style={{zIndex:"1000"}}>

<img src={logo} alt="logo" onClick={onLogoClick} />
</div>
          {/* <div className="search__box">
            <img src={searchLogo} alt="search" className="search__logo" />
            <input className="search__box-input" placeholder="Search"></input>
          </div> */}
          <div className="admin">
            {/* <img
              src={bell}
              alt="bell"
              className="bell"
              onClick={() => setShowApplications((p) => !p)}
            /> */}
          </div>
          <button className="btn btn-primary" onClick={()=>setShow(true)}>
            Logout
          </button>
        </div>
         {children}
      </div>
    </div>
  );
};

export default Layout;
Layout.propTypes = {
  children: PropTypes.element
}