import React, { useEffect, useState } from "react";
import "./chat.css";
import Layout from "../Layout";
import ChatList from "./ChatList";
import jwt from "jwt-decode";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "../../../services/url";
// const baseUrl = process.env.API_URL;
const baseUrl = API_URL;

const Chat = () => {
  const [chatLists, setChatLists] = useState([]);
  
  const fetchChatList = async () => {
    try {
      const token = localStorage.getItem("token");
      
      const res = await axios.get(baseUrl + "/api/business/invcons", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const arrs = res.data.data.reverse();
      const filteredArr = arrs.reduce((acc, current) => {
        const x = acc.find(item => item.consultant_id === current.consultant_id && item.project_id === current.project_id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      // console.log("the arr ",filteredArr);

      setChatLists(filteredArr);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
   
    fetchChatList();
  }, []);
  async function setChatDone (id){
    const token = localStorage.getItem("token");
    const NewMsgFormData = new FormData();
    NewMsgFormData.append("inv_con_id", 186);
    NewMsgFormData.append("val", 0);
    const res2 = await axios.post(baseUrl + "/api/business/invcons/update",
    {
      inv_con_id : id,
      val: 0
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    console.log(res2);
    // console.log("this is res2 : ",res2);
    fetchChatList();
  }
// const b_id =  ;


  return (
    <Layout>
      <div className="container-fluid  py-5">
        <div className="container">
          {/* <h3 className=" text-center text-capitalize">Project Chating</h3> */}
          {/* <p className=" text-center">all about react native</p>w */}
          {/* <p className=" text-center">Team created by @tushar</p> */}
          <div className="messaging">
            <div className="inbox_msg">
              <div className="inbox_people">
                <div className="headind_srch">
                  <div className="recent_heading">
                    <h5>Applied Consultant</h5>
                  </div>
                </div>
                <div className="inbox_chat">
                  {chatLists.map((list, key) => (
                    <Link
                    onClick={()=>setChatDone(list.inv_con_id)}
                    to={{
                      pathname: "/business/dashboard/chatscreen/" + list.project_id,
                      customobject: {
                        project_id: list.project_id,
                        business_id: jwt(localStorage.getItem("token")).data.business_id,
                        consultant_id: list.consultant_id,
                        inv_con_id: list.inv_con_id,
                        new:list.new
                      },
                    }}
                    key={key}
                    >
                      <ChatList chatList={list} />
                    </Link>
                  ))}
                </div>
              </div>
              {/* <div className="mesgs">
                <div className="msg_history">
                  <IncomingMessage />
                  <OutgoingMessage />
                </div>
                <div className="type_msg">
                  <div className="input_msg_write">
                    <textarea
                      className="form-control"
                      placeholder="Type a message"
                      name="message"
                      id="message"
                      // value={props.message}
                      // onChange={props.handleChange}
                      // error={props.error.message}
                    />
                    <button
                      className="btn btn-lg w-100 btn-dark"
                      style={{ float: "right" }}
                      type="button"
                    >
                      <i className="far fa-comment-dots text-warning">SEND</i>
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chat;
