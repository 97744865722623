import React from "react";
import "./ProjectStyles.css";
import PropTypes from "prop-types";

const ProjectCard = ({  title, subtitle, description,file }) => {
  return (
    <div className="project-card">
      <div className="image-trending">
      <img style={{height:"200px"}} src={file[0].file_url} alt="" />
      </div>
      
      <div className="title" style={{wordBreak: 'break-all'}}>{title}</div>
      <p className="subtitle" style={{wordBreak: 'break-all'}}>{subtitle}</p>
      {description.substring(0, 100)}...
    </div>
  );
};

export default ProjectCard;

ProjectCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  file: PropTypes.array

};