import React, { useState, useEffect} from "react";
import { getAllBlogs } from "../../api/blogs";
import BlogCard from "./blogCard";
import Icon from './Blogs.jpg';
import "./allBlogs.css";
import BlogHeader from "./blogheader";


export default function AllBlogs() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    try {
      getAllBlogs().then((res) => {
        setBlogs(res.data);
        // console.log("kbhjgfd",res.data);
      }).catch((error) => {
        console.log("error", error)
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div>
      <BlogHeader />
      <section className="allBlogs">
        <div className="BlogImage">
          <img className="IMG" src={Icon} alt="" />
        </div>
        
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-light" >




          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupported" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse w-100 " style={{ backgroundColor: "#f8f9fa" }} id="navbarSupported">
            <ul className="navbar-nav m-auto ">
              <li className="nav-item">
                <div>All Topics</div>
              </li>
              <li className="nav-item">
                <div >Flexible Workforce</div>
              </li>
              <li className="nav-item">
                <div>Freelance Economy</div>
              </li>
              <li className="nav-item">
                <div>Future of work</div>
              </li>
              <li className="nav-item">
                <div>Strategy Execution</div>
              </li>
              <li className="nav-item">
                <div>Human Resources and Organizations</div>
              </li>
            </ul>


          </div>
        </nav> */}

        <div>
          {blogs.map((blog, blog_id) => {
            return (
              <div key={blog_id}>
                <BlogCard key={blog_id} blog={blog} />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
