export const profileFormModel = {
  tagline: "",
  introduction: "",
  linkedin: "",
  role: "",
  company: "",
  responsibilities: "",
  work_from_month: "",
  work_from_year: "",
  work_to_month: "",
  work_to_year: "",
  currently_working: 0,
  degree: "",
  university: "",
  education_from_month: "",
  education_from_year: "",
  education_to_month: "",
  education_to_year: "",
  skills: "",
  industry: "",
  location: "",
  languages: "",
  consultant_id: "",
};
