import axios from "axios";
import { API_URL } from "../services/url";
// const baseUrl = process.env.API_URL;
const baseUrl = API_URL;

export default function query(email){
    const post ={
        email_id:email
    }
    const url = baseUrl + "/api/query";
    return axios.post(url, post);
} 
