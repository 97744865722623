import React, {  useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const One = ({ step, values, onChange, fetch,onSubmit }) => {
  const validate = (e)=>{
    var err = "";
    if(values.tagline == "" ) err = "Enter tagline";
    if(values.introduction == "") err = "Enter Introduction";
    if(values.linkedin == "") err = "Enter LinkedIn";
    if(err) {
      e.preventDefault();
      alert(err);}
    else{
      onSubmit();
    }
  }
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="one">
      <div className="one__header">
        <div className="title">Enter your credentials!</div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="one__form">
        <div className="one__form__input-field">
          <div className="one__form-label">Tagline*</div>
          <input required
            type="text"
            placeholder="Enter your tagline"
            name="tagline"
            value={values.tagline}
            onChange={onChange}
            maxLength={100}
          />
          <div className="one__form-input-caption">100 words max</div>
        </div>
        <div className="one__form__input-field">
          <div className="one__form-label">Introduction*</div>
          <div className="one__form-Enter-intro">
            <input required
              type="text"
              placeholder="Enter your Intro"
              name="introduction"
              value={values.introduction}
              onChange={onChange}
              maxLength={200}
            />
            <div className="one__form-input-caption">200 words max</div>
          </div>
        </div>
        <div className="one__form__input-field">
          <div className="one__form-label">Profile Picture*</div>
          <div className="one__form-Enter-intro">
            
            <label htmlFor="image"className="imageBtn">
            <input required
              type="file"
              name="image"
              id="image"
              hidden
              // value={values.filename}
              onChange={(e)=>{
                if(e.target.files[0].type == "image/png" || e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg"){
                  console.log(e.target.files)
                onChange(e)
                  
                }
                else {
                  e.preventDefault();
                  alert("PLease select an image file")
                }
              }}
             
            />
              Choose Image
              </label>
           <p>{values.filename}</p>
          </div>
        </div>
        <div className="one__form__input-field">
          <div className="one__form-label">LinkedIn*</div>
          <input required
            type="text"
            placeholder="Enter your LinkedIn ID"
            name="linkedin"
            value={values.linkedin}
            onChange={onChange}
            maxLength={100}
          />
        </div>
      </div>
      <div className="one__footer">
        <Link
          to={`/profile-creation/${parseInt(step, 10) - 1}`}
          className="btn btn-outline footer-btns"
        >
          Back
        </Link>
        <Link
          to={`/profile-creation/${parseInt(step, 10) + 1}`}
          className="btn btn-primary footer-btns"
        onClick={e=>validate(e)}
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default One;

One.propTypes = {
  step: PropTypes.number,
  values: PropTypes.object,
  fetch:PropTypes.func,
  onSubmit:PropTypes.func,
  onChange :PropTypes.func
};