import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Link  } from "react-router-dom";
import "./ProfileCreation.css";
import { profileFormModel } from "../../models/ProfileFormModel";
import SideImg from "../../Images/profile-creation__side-img.png";
import logo from "../../Images/wenester-logo.png";
import PageNotFound from "../PageNotFound";
import Zero from "./Zero";
import One from "./One";
import Two from "./Two";
import Three from "./Three";
import Four from "./Four";
import Five from "./Five";
import { API_URL } from "../../services/url";
import Errorpage from "../errorpage";
import { CONSULTANT, BUSINESS } from "../../constants/index";
const IndustryTypes = [
  { value: 1, label: "Agriculture" },
  { value: 2, label: "Consumer Goods" },
  { value: 3, label: "Consumer Services" },
  { value: 4, label: "Education" },
];
const Languages = [
  { value: 1, label: "English" },
  { value: 2, label: "Hindi" },
  { value: 3, label: "Sanskrit" },
  { value: 4, label: "French" },
];
const ProfileCreation = (props) => {
  const step = props.match.params.step;
  const [loading, setLoading] = useState(false);
  if (step < 0 || step > 6) return <PageNotFound />;
  const [values, setValues] = useState(profileFormModel);
  const [load,seLoad] = useState(0);
  const fetchProfileDetails = async () => {
    let token = localStorage.getItem("token"); // get token fron localstorage
    const id = localStorage.getItem("consultant_id");
    if(id){

      try {
        const response = await axios.get(
          `${API_URL}/api/consultant/profile/${id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
          );
          if (response.status !== 200) {
            onSubmit();
            return;
          }
          // console.log("posfile : ", response.data.data);

          var data = response.data.data[response.data.data.length - 1];
          var indus = data.industry.split(",");
          var lang = data.languages.split(",");
          indus = indus.filter(val=> val != '');
          lang = lang.filter(val=> val != '');
          let langArr = [];
          let indusArr = [];
          console.log("full data ",data);
          for(var j = 0;j<lang.length;j++){
            let obj = Languages.find(o => o.label === lang[j]);
            if(obj) langArr.push(obj);
          }
      
          for(j = 0;j<indus.length;j++){
            let obj = IndustryTypes.find(o => o.label === indus[j]);
            console.log("object : ",obj);
            indusArr.push(obj);
          }
          
          data.education_from_month = data.education_from.split("/")[0];
          data.education_from_year = data.education_from.split("/")[1];
          data.education_to_month = data.education_to.split("/")[0];
          data.education_to_year = data.education_to.split("/")[1];
          data.work_from_month = data.work_from.split("/")[0];
          data.work_from_year = data.work_from.split("/")[1];
          data.work_to_month = data.work_to.split("/")[0];
          data.work_to_year = data.work_to.split("/")[1];
          data.languages = langArr;
          data.industry = indusArr;
          
          setValues(data);
        } catch (error) {
          console.error(error);
        }
      }
      else{
        alert("No consultant link found");
      }
  };
  useEffect(() => {
    fetchProfileDetails();
    seLoad(1);
  }, [load]);

  const onChange = ({ target }) => {
    
    if(target.name == "image"){
      console.log("target : ",target.files[0].name)
      const newValue = { ...values, [target.name]: target.files[0],filename:target.files[0].name };
      setValues(newValue);
      console.log("values : ",values);
      return;
    }
    const newValue = { ...values, [target.name]: target.value };
    setValues(newValue);

  };

  async function onSubmit() {
    console.log("submiting...", values);
    const id = localStorage.getItem("consultant_id");

    var data = {
      tagline: values.tagline,
      introduction: values.introduction,
      linkedin: values.linkedin,
      role: values.role,
      company: values.company,
      responsibilities: values.responsibilities,
      work_from: `${values.work_from_month}/${values.work_from_year}`,
      work_to: `${values.work_to_month}/${values.work_to_year}`,
      currently_working: values.currently_working,
      degree: values.degree,
      university: values.university,
      education_from: `${values.education_from_month}/${values.education_from_year}`,
      education_to: `${values.education_to_month}/${values.education_to_year}`,
      skills: values.skills,
      industry: values.industry,
      location: values.location,
      languages: values.languages,
      consultant_id: id,
      image :values.image,
      filename:values.filename ? values.filename : ""
      
    };
   
   
    // console.log("here is the data", data);
    const token = localStorage.getItem("token");
    setLoading(true);
    let formData = new FormData();



 
    formData.append("introduction", values.introduction);
    formData.append("linkedin", values.linkedin);
    formData.append("role", values.role)
    formData.append("company", values.company)
    formData.append("responsibilities", values.responsibilities)
    formData.append("work_from", `${values.work_from_month}/${values.work_from_year}`)
    formData.append("work_to", `${values.work_to_month}/${values.work_to_year}`)
    formData.append("currently_working", values.currently_working)
    formData.append("degree", values.degree)
    formData.append("university", values.university)
    formData.append("education_from", `${values.education_from_month}/${values.education_from_year}`)
    formData.append("education_to", `${values.education_to_month}/${values.education_to_year}`)
    formData.append("skills", values.skills)

    formData.append("location", values.location)

    formData.append("consultant_id", id)
    formData.append("image",values.image)
    formData.append("filename",values.filename ? values.filename : "")
    formData.append("tagline",values.tagline );
    formData.append("file",values.file);
    console.log("Data",formData);
    var string = "";
    console.log("len",values.industry.length)
    for(var i = 0;i<values.industry.length;i++){
      string += values.industry[i].label;
      if(i<values.industry.length - 1) string += ",";
    }
    data.industry = string;
    formData.append("industry", string)
    string = "";
    for(i = 0;i<values.languages.length;i++){
      string += values.languages[i].label;
      if(i<values.languages.length - 1) string += ",";
    }
    data.languages = string;
    formData.append("languages", string);
    try {
      const response = await axios.post(
        `${API_URL}/api/consultant/profile`,
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("the response : ", response);
      if (response) {
        fetchProfileDetails();
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);

  }

  let tmp = <Zero step={step} />;
  if (step == 1)
    tmp = ( !loading ?<One
      step={step}
      values={values}
      onChange={onChange}
      fetch={fetchProfileDetails}
    onSubmit={onSubmit}
    /> : <p>Loading ..</p> 
      
    );
  else if (step == 2)
    tmp = (!loading ? 
      <Two
        step={step}
        values={values}
        onChange={onChange}
        fetch={fetchProfileDetails}
      onSubmit={onSubmit}
      /> : <p>Loading ...</p>
    );
  else if (step == 3)
    tmp = ( !loading ?
      <Three
        step={step}
        values={values}
        onChange={onChange}
        fetch={fetchProfileDetails}
      onSubmit={onSubmit}
      /> : <p>Loading ...</p>
      
    );
  else if (step == 4)
    tmp = ( !loading ?
      <Four
        step={step}
        values={values}
        onChange={onChange}
        fetch={fetchProfileDetails}
      onSubmit={onSubmit}
      /> : <p>Loading ...</p>
      
    );
  else if (step == 5)
    tmp = ( !loading ?
      <Five
        step={step}
        values={values}
        onChange={onChange}
        fetch={fetchProfileDetails}
        onSubmit={onSubmit}
        /> : <p>Loading ...</p>
      
    );
  return (
    <>
    {localStorage.getItem("person") != CONSULTANT && localStorage.getItem("person") != BUSINESS ? <Errorpage /> : <>

    <div className="profile-creation">
      <div>
      <div className="profile-creation__side-img">
        <img src={SideImg} alt="" />
      </div>
      </div>
      <div className="profile-creation__content">
        <div className="profile-creation__header">
          <Link to="/">
            <img src={logo} className="profile-creation__header-logo" />
          </Link>
          <Link to="/contactus">Help and support</Link>
        </div>
        <div className="profile-creation__main">{tmp}</div>
      </div>
    </div>
    </>}
    </>
  );
};

export default ProfileCreation;

ProfileCreation.propTypes = {
  match: PropTypes.object.isRequired,
};
