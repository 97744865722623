import React from "react";
import Heading from "../common/Heading";
import Consultants from "../../Images/consultants.svg";


export default function HowWeWork() {
  return (
    <div className="">
      <section className="section mt-5">
        <div className="container mt-5 howwework">
          <div className="jumbotron">
            <div className="container px-5 mt-5">
              <Heading heading="How does it work?"></Heading>
            </div>
            <div className="container ">
              <div className="container ">
                <div className="row ">
                  <div className="col-lg-6 col-sm-12">
                    <img
                      src={Consultants}
                      alt="Consultants Image"
                      className="img-responsive"
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 pt-5">
                    <h3 className="">
                      Find the <span style={{ color: "#01C853" }}>expert</span>{" "}
                      in 3 simple steps!
                    </h3>
                    <p className="pt-5 display-5">
                      <ol className="nobl ">
                        

                        <li>
                        Post your project containing problem statement and initiative along with your expectations

                        </li>
                        <p></p>
                        <li>
                        Take note of expert&apos;s approaches and feedback, and connect over the platform to discuss in more detail the best expert to hire

                        </li>
                        <p></p>
                        <li>
                        Getting the solution delivered and paying your consultant will ensure that he keeps engaging with you to grow your business.
                        </li>
                        <p></p>
                      </ol>
                    </p>
                    <div className="createProjectbutton">
                      <a href="/register/1">
                      <button
                        style={{ background: "#01BD4E", color: "white" }}
                        className="btn btn-outline-secondary btn-lg btn-block">
                        Create Project
                      </button>
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div></div>
    </div>
  );
}
