import React, { useState, useEffect } from "react";
import { getAllBlogs } from "../../api/blogs";

import Layout from "./Layout";
import Activity from "./Activity";
import Recommended from "./Recommended.js";
import demo from "../../Images/blog2.png";
import dashimg from "../../Images/dashimg.png";
import {Link} from "react-router-dom";
import { CONSULTANT, BUSINESS } from "../../constants";
import "./Dashboard.css";
const Dashboard = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogCt,setBlogct] = useState(5);
  const [name, setName] = useState(localStorage.getItem("name"));
  useEffect(()=>{

    setName(localStorage.getItem("name"))
  })
  useEffect(() => {
    console.log("hgasiy");
    var user = localStorage.getItem("person");
    console.log(user);
    if (user != CONSULTANT && user != BUSINESS) {
      console.log("here");
      window.location.replace("localhost:3001/")
    }
  }, []);
  useEffect(() => {
    try {
      getAllBlogs().then((res) => {
        setBlogs(res.data.splice(5));
        console.log("kbhjgfd",res.data);
      }).catch((error) => {
        console.log("error", error)
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      {localStorage.getItem("person") != CONSULTANT && localStorage.getItem("person") != BUSINESS ? <>You are not authorized to access it</> : <>
      <Layout>
        <div className="main d-flex flex-column justify-content-center align-center">
          <div className="top row justify-content-center align-center">
            <div className="card-intro bg-primary">
              <div className="introtext">
                <h2>Hello {name}</h2>
                <h4>Ready to get your day started?</h4>
              </div>
              <div>
                <img src={dashimg}></img>
              </div>
            </div>
            <div className="card-id card-2-intro">
              <div className="weekly-head">
                Weekly Overview
                <div className="weekly-text">1Apr - 7Apr</div>
              </div>
              <Link to="/comingsoon" className="card mx-auto chart">Chart</Link>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-start flex-wrap">
            <div className="card-id">
              <div className="row">
                <h5 className="recent col">Recent Activity</h5>

              </div>
              <div className="activities">
                
                {blogs.slice(0,blogCt).map((blog)=>{
         
                  return <Activity key={blog.blog_id} type={blog.title} subtype={blog.writtenby} time={blog.blog_id} />
                })}
                <div className="moreClick" onClick={()=>setBlogct(prev=> prev==5? blogs.length : 5)}> {blogCt===blogs.length ? "Less" :"More"}</div>
                
        
              </div>
            </div>
            <div className="card-id">
              <Recommended
                imageUrl={demo}
                by="by Ajay Malhotra"
                title="Need a designer to form branding essentials for my business"
                desc="looking for a graphic designer to create all the branding assets for my startup"
                value="3500$"
                type="Full Time"
              />
            </div>
          </div>
        </div>
      </Layout>
      </>}
    </>
  );
};

export default Dashboard;
