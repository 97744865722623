import axios from "axios";
import { API_URL } from "../services/url";
// const baseUrl = process.env.API_URL;
const baseUrl = API_URL;

export default function query(data){
    const post ={
        email_id:data.email,
        content: data.content,
        name: data.first_name + " " + data.last_name
    }
    const url = baseUrl + "/api/query/contact";
    return axios.post(url, post);
} 
