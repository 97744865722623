import React from "react";
import ExpertCard from "./ExpertCard";
import "./OurExperts.css";
import expertsDiscuss from "../../../Images/experts-discuss.png";
import PropTypes from "prop-types";

const OurExperts = ({ experts }) => {
  // console.log("experts : ", experts);
  return (
    <>
      <div className="experts-heading">Our Experts</div>
      <div className="our-experts">
        {experts.length > 0 &&
          experts.map(({ consultant_id, tagline,name, skills,file }) => (
            <ExpertCard
              key={consultant_id}
              img={file ? file : expertsDiscuss}
              title={tagline? tagline : name}
              subtitle={skills=='undefined'? 'Hard Working' :skills }
            />
          ))}


      </div>
    </>
  );
};

export default OurExperts;
OurExperts.propTypes = {
  experts : PropTypes.array
}