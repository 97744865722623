import React,{useState} from 'react'
import "./contact.css";
import contacts from "../api/contact";
export default function Contact() {
  const [contact,setContact] = useState({
    first_name:"",
    last_name:"",
    contact:"",
    job_title:"",
    email:"",
    comment:""
  })
  const onChange = ({ target }) => {
    setContact({ ...contact, [target.name]: target.value });
  };
  const submitForm =()=>{
    console.log(contact);
    if(contact.first_name=="" && contact.last_name=="" && contact.contact=="" && contact.email=="" && contact.comment=="") alert("fill ful form !!");
    else contacts(contact);
  }
  return (
    <div className="hdiw">
      <div className="hdiw__header">
        <div className="hdiw__header-title">Contact Us</div>
        <div className="hdiw__header-sub-title">
        For any support, Please mail us to wenester@gmail.com
        </div>
        <form action="" className="contact-form">
          <div className="contactus-inputfrom-div">
          <div>
          <label htmlFor="first_name"> First Name</label>
            <input
            value={contact.first_name}
            onChange={onChange}
            type="text" name="first_name" id="first_name" />
          </div>
          <div>

            <label htmlFor="first_name"> Last Name</label>
            <input
                  value={contact.last_name}
                  onChange={onChange}
            type="text" name="last_name" id="last_name" />
          </div>
          </div>
          <div className="contactus-inputfrom-div">
          <div>
          <label htmlFor="contact"> Contact No</label>
            <input
                  value={contact.contact}
                  onChange={onChange}
            type="text" name="contact" id="conact" />
          </div>
          <div>

            <label htmlFor="job_title"> Job Title</label>
            <input
                  value={contact.job_title}
                  onChange={onChange}
            type="text" name="job_title" id="job_title" />
          </div>
          </div>
          <div className="input-div">
            <label htmlFor="email">Email Address</label>
            <input 
                  value={contact.email}
                  onChange={onChange}
            type="text" name="email" id="email" />
          </div>
          <div className="input-div">
            <label htmlFor="content">How we can help you:</label>
            <textarea
                  value={contact.comment}
                  onChange={onChange}
            name="comment" cols="20" rows="20"></textarea>
          </div>
          <button
          onClick={()=>{submitForm()}} className="btn btn-primary">submit</button>
        </form>
        </div>
        </div>
  )
}
