import React, {useState} from "react";
import { Link,useHistory  } from "react-router-dom";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { logout } from "../../redux/actions/loginAction";
import "./Header.css";
// import { CONSULTANT } from "../../constants";
import logo from "../../Images/wenester-logo.png";
import {Modal,Button} from 'react-bootstrap';

function Header() {
  const history = useHistory();
  const Logout = () => {
    console.log(localStorage.getItem("person"));
    localStorage.removeItem("person");
    console.log("user : ",localStorage.getItem("person"));
    history.push("/login");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you  want to Logout ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={Logout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    <Link to=  {localStorage.getItem("person")? localStorage.getItem("person")=="CONSULTANT" ? "/dashboard" : "/business/dashboard/projects" : "/" }>
          {" "}
          <img src={logo} alt="logo" />
        </Link>

  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" style={{backgroundColor:"#f8f9fa"}} id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
      <a href="/blogs">Resources</a>
      </li>
      <li className="nav-item">
      <Link to="/about-us">About us</Link>
      </li>
      
      <li className="nav-item">
      <Link to="/how-does-it-work">How does it work</Link>
      </li>
      <li className="nav-item">
      <Link to="/contactus">Contact us</Link>
      </li>

    </ul>
      {localStorage.getItem("person") ? <div className="buttons">
<span className="btn btn-outline" onClick={()=>{
  setShow(true)
console.log("item : ",localStorage.getItem("person"));
}
}>
Logout
</span>

      </div> : <div className="buttons">
          <Link to="/register/1" className="btn btn-outline">
            Apply as expert
          </Link>
          <Link to="/register/1" className="btn btn-dark">
            Find expert
          </Link>
        </div>}
    
  </div>
</nav>
      {/* <div className="navbar">
      
        <div className="nav-items">
          <ul>
            <li>
             
            </li>
            <li>
              <Link to="/about-us">About us</Link>
            </li>
            <li>
              <Link to="/how-does-it-work">How does it work</Link>
            </li>
            <li>
              <Link to="/contactus">Contact us</Link>
            </li>
          </ul>
        </div>
        <div className="buttons">
          <Link to="/register/1" className="btn btn-outline">
            Apply as expert
          </Link>
          <Link to="/register/1" className="btn btn-dark">
            Find expert
          </Link>
        </div>
      </div> */}
    </>
  );
}
const matchStateToProps = (state) => {
  return { login: state.login, person: state.person };
};
const matchDispatchToProp = {
  logout,
};
export default connect(matchStateToProps, matchDispatchToProp)(Header);
// Header.propTypes = {
//   login: PropTypes.bool.isRequired,
//   logout: PropTypes.func.isRequired,
//   person: PropTypes.string.isRequired,
// };
