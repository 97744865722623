import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
const Two = ({ step, values, onChange, fetch, onSubmit }) => {
  const [working, setWorking] = useState(true);
  const tomonth = useRef(null);
  const [profCount, setProfCount] = useState([]);
  const setProf = (e)=>{
    profCount.push({
      work_from_month:"",
      work_from_year:"",
      work_to_year:"",
      work_to_month:"",
    })
    console.log(e);
    setProfCount(profCount);
  }
  const setCurrentDate = (e)=>{
    setWorking(!working);
    console.log(e);
  }
  useEffect(() => {
    fetch();
  }, []);
  const validate = (e)=>{
    var err = "";
    if(values.responsibilities == "" ) err = "Enter your Responsibility";
    if(values.role == "") err = "Enter Role";
    if(values.company == "") err = "Enter Company";
    if(values.work_from_month == "") err = "Enter Date";
    if(values.work_from_year == "") err = "Enter Date";
    console.log("error : ",err);
    if(err) {
      e.preventDefault();
      alert(err);
    }
    else{
      onSubmit();
    }
    console.log(e);

  }
  return (
    <div className="one">
      <div className="one__header">
        <div className="title">Enter your credentials!</div>
        <span className="bg-green"></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="one__form-heading">Work Experience</div>
      <div className="one__form">
        <div className="one__form-role-and-company">
          <div className="one__form__input-field role">
            <div className="one__form-label">Role*</div>
            <input required
              type="text"
              placeholder="Enter your role"
              value={values.role}
              name="role"
              onChange={onChange}
              maxLength={200}

            />
          </div>
          <div className="one__form__input-field company">
            <div className="one__form-label">Company*</div>
            <input required
              type="text"
              placeholder="Enter your company"
              name="company"
              value={values.company}
              onChange={onChange}
              maxLength={800}

            />
          </div>
        </div>

        <div className="one__form__input-field">
          <div className="one__form-label">Responsibilities*</div>
          <div className="one__form-Enter-intro">
            <input required
              type="text"
              placeholder="Enter your responsibilities"
              name="responsibilities"
              value={values.responsibilities}
              onChange={onChange}
              maxLength={200}

            />
            <div className="one__form-input-caption">200 words max</div>
          </div>
        </div>
        <div className="one__form__input-field date">
          <div className="form__date-from">
            <div className="one__form-label from">From*</div>
            <div className="from">
              <input required
                type="text"
                placeholder="Month"
                name="work_from_month"
                value={values.work_from_month}
                onChange={onChange}
              maxLength={10}

              />
              <input required
                type="text"
                placeholder="Year"
                name="work_from_year"
                value={values.work_from_year}
                onChange={onChange}
              maxLength={10}

              />
            </div>
          </div>
          {working ? 
          <div className="form__date-to">
            <div className="one__form-label to">To*</div>
            <div className="to">
              <input required
                type="text"
                placeholder="Month"
                name="work_to_month"
                value={values.work_to_month}
                onChange={onChange}
                ref= {tomonth}
              maxLength={10}

                />
              <input required
                type="text"
                placeholder="Year"
                name="work_to_year"
                value={values.work_to_year}
                onChange={onChange}
              maxLength={10}

                />
            </div>
          </div>: <></>
              }

          <div className="one__form-currently-working">
            <input required
              type="checkbox"
              name="currently_working"
              
              onChange={(e)=>{setCurrentDate(e)}}
              
            />
            <div className="currently-working">Currently working</div>
          </div>
        </div>
              {profCount.map((ct) => {
              
                 return <div className="one__form__input-field date" key={ct}>
                <div className="form__date-from">
                  <div className="one__form-label from">From*</div>
                  <div className="from">
                    <input required
                      type="text"
                      placeholder="Month"
                      name="work_from_month"
                      
                    />
                    <input required
                      type="text"
                      placeholder="Year"
                      name="work_from_year"
                      
                    />
                  </div>
                </div>
                
                <div className="form__date-to">
                  <div className="one__form-label to">To*</div>
                  <div className="to">
                    <input required
                      type="text"
                      placeholder="Month"
                      name="work_to_month"
                      
                    
                      />
                    <input required
                      type="text"
                      placeholder="Year"
                      name="work_to_year"
                 
                      />
                  </div>
                </div>
      
           
              </div>
              })}
      
        <div className="add-work-experience-btn" onClick={e=>setProf(e)}>
          Add work experience
        </div>
      </div>

      <div className="one__footer">
        <Link
          to={`/profile-creation/${parseInt(step, 10) - 1}`}
          className="btn btn-outline footer-btns"
        >
          Back
        </Link>
        <Link
          to={`/profile-creation/${parseInt(step, 10) + 1}`}
          className="btn btn-primary footer-btns"
          onClick={e=>validate(e)}
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default Two;

Two.propTypes = {
  step: PropTypes.number,
  values: PropTypes.object,
  fetch:PropTypes.func,
  onSubmit:PropTypes.func,
  onChange :PropTypes.func
};
