import React,{ useEffect, useState } from "react";
import Main from "../content/Main/Main";
import About from "../content/About/About";
import TrendingProjects from "../content/TrendingProjects/TrendingProjects";
import OurExperts from "../content/OurExperts/OurExperts";
import HowWeWork from "../content/HowWeWork";
import SkillsNav from "../header/SkillsNav.js";
import { getHomePageData } from "../../api/home";
import {loadAllProjects } from "../../api/projectsApi";

export default function Home() {
  const [skills, setSkills] = useState([]);
  const [trendingProjects, setTrendingProjects] = useState([]);
  const [experts, setExperts] = useState([]);

  useEffect( () => {
    try {
      getHomePageData().then((data)=>{
        setSkills(data.data.data);
        let arr = [];
        console.log("data : ", data.data.consultant);
        var consultants =  data.data.consultant.reverse();
        for(let i = 0;i< data.data.consultant.length;i++){
          if(arr.findIndex(item => item.consultant_id == consultants[i].consultant_id) != -1) continue;
          else arr.push(consultants[i]);
        }
        console.log("arr",arr);
        setExperts(arr);
      });
      loadAllProjects().then((project)=>{
        console.log("Thes are the projects : ",project.data.data);
        setTrendingProjects(project.data.data);
      });
    }catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div className="">
      <SkillsNav experts={experts} skills={skills} />
      <Main />
      <About />
      <TrendingProjects trendingProjects={trendingProjects} />
      <OurExperts experts={experts} />
      <HowWeWork />
    </div>
  );
}
