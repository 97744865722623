import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Layout.css";
import { Modal, Button } from 'react-bootstrap';
import PropTypes from "prop-types";

import Sidebar from "../expert_utils/sidebar"
import profileimg from "../../Images/blog2.png";
// import Invites from "./Notification/Invites";
import logo from "../../Images/wenester-logo.png";
const Layout = ({ children }) => {
  const [name, setName] = useState(localStorage.getItem("name"));
  // setName(localStorage.getItem("name"))
  useEffect(()=>{

    setName(localStorage.getItem("name"))
  })
  console.log("Header", name);
  const [side, setside] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const history = useHistory();
  const Logout = () => {
    localStorage.removeItem("person");
    history.push("/login");
  };
  const onLogoClick = () => {

    setside(prevstate => !prevstate);
  }
  const clossPannel = (e) => {
    console.log("roifjg");
    // 
    console.log("list : ", e.target.classList[0]);
    if (e.target.classList[0] !== "sds") setside(false);
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you  want to Logout ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={Logout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout" onClick={(e) => clossPannel(e)}>
        <div className={side ? "" : "hidden-sidebar"}>
          <Sidebar />
        </div>
        <div className="layout__main">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div style={{ zIndex: "1000" }} className="sds" onClick={() => onLogoClick()}>

              <img src={logo} alt="logo" className="sds" />
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-between " style={{ backgroundColor: "#f8f9fa" }} id="navbarSupportedContent">
              <div></div>

              <div className="d-flex h-10 align-items-center">

                <img
                  src={profileimg}
                  className="rounded-circle profimg"
                  alt="profile img"
                ></img>
                <div className="text">{name}</div>
                <button className="btn btn-primary" onClick={() => setShow(true)}>
                  Logout
                </button>
              </div>

            </div>
          </nav>

          <div className="bb-body" onClick={clossPannel}>
            {children}
          </div>
        </div>
      </div>
    </>
  );

};

export default Layout;
Layout.propTypes = {
  children: PropTypes.element
}