import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../services/url";
// const baseUrl = process.env.API_URL;
const baseUrl = API_URL;
import jwt from "jwt-decode";
import PropTypes from "prop-types";

import io from "socket.io-client";
var socket, selectedChatCompare;
socket = io(baseUrl);
const ChatList = ({ chatList }) => {
  const [projectDetails, setProjectDetails] = useState([]);
  const [newChat, setNewChat] = useState(-10);
  console.log("userchat : ",chatList);

  useEffect(() => {
    socket.emit("setup", jwt(localStorage.getItem("token")).data);
    socket.on("connected", () => {
      console.log("socket connected");
    });

    // eslint-disable-next-line
  }, [socket]);
  useEffect(() => {
    console.log("checking socket ",socket);
    if (socket) {
      console.log("after socket !!!", selectedChatCompare);

    socket.on("message recieved", (newMessageRecieved,inv_con_id) => {
      console.log("new",inv_con_id,chatList.inv_con_id)
      setNewChat(inv_con_id);
    });
  }
  },[socket]);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem("token");

        const res = await axios.get(
          baseUrl + "/api/business/project/" + chatList.project_id,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(res);
        // setState(res.data.data);
        console.log("projects ", res.data.data);

        setProjectDetails(res.data.data);
        // setExperts(res.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchProject();
  }, []);

  // console.log( "title",projectDetails.map((list,key)=>(
  //   list.title
  //   )));

  return (
    <div
      id={"cl_"}
      className="chat_list"
     
    >
      <div className="chat_people">
      {chatList.new==3 || newChat==chatList.inv_con_id ? <div className="green-dot"></div> : undefined
      }
        <div className="chat_img">
          {" "}
          <img
            src={chatList.image ? chatList.image : "https://ptetutorials.com/images/user-profile.png"}
            alt="avatar"
          />{" "}
        </div>
        <div className="chat_ib">
          <h3>{chatList.name}</h3>
          {projectDetails.map((list, key) => (
            <p key={key}>{list.title}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatList;
ChatList.propTypes = {
  chatList : PropTypes.object
}