import React from 'react'
import { NavLink} from "react-router-dom";
import logo from "../../Images/wenester-logo.svg";
import dashicon from "../../Images/dash.png";
import projicon from "../../Images/projicon.png";
import blogicon from "../../Images/blogicon.png";
import blogChaticon from "../../Images/blog.png";
import chaticon from "../../Images/chat.png";
import usericon from "../../Images/user.png";
import PropTypes from "prop-types";

export default function sidebar({setside}) {
    return (
        <div>
            <div className="sds sidebar">
            <p style={{position: 'absolute', right:"10px", top:"10px", color:"white", cursor:"pointer"}} onClick={()=>setside(false)} >x</p>

                <div
                    className="sds sidebar__logo"
                    style={{ backgroundImage: `url(${logo})` }}
                ></div>
                <nav className="sds sidebar__nav">
                <div className="sds sidebar__heading">
                <NavLink to="/dashboard" activeClassName="active">
                    <span className="sds dash-icon">
                        <img src={dashicon} />
                    </span >
                    Dashboard
                    </NavLink>
                </div>
                    <NavLink to="/blogs" activeClassName="active">
                        <span className="sds dash-icon">
                            <img src={blogChaticon} />
                        </span>
                        Blogs
                    </NavLink>
                    <NavLink to="/dashboard/create" activeClassName="active">
                        <span className="sds dash-icon">
                            <img src={blogicon} />
                        </span>
                        Create Blog
                    </NavLink>
                    <NavLink to="/profile-creation/1" activeClassName="active">
                        <span className="sds dash-icon">
                            <img src={usericon} />
                        </span>
                        Profile
                    </NavLink>
                    <NavLink to="/dashboard/chat" activeClassName="active">
                        <span className="sds dash-icon">
                            <img src={chaticon} />
                        </span>
                        Chat
                    </NavLink>
                    <NavLink to="/consultant/projects" activeClassName="active">
                        <span className="sds dash-icon">
                            <img src={projicon} />
                        </span>
                        Projects
                    </NavLink>
                    {/* <NavLink to="/dashboard/#" activeClassName="active">
                        <span className="dash-icon">
                            <img src={settingsicon} />
                        </span>
                        Settings
                    </NavLink> */}
                </nav>
            </div>
        </div>
    )
}
sidebar.propTypes = {
    setside: PropTypes.func
}