import React from "react";
import "./ExpertCardStyles.css";
import PropTypes from "prop-types";

const ExpertCard = (props) => {
  // console.log(props);
  return (
    <div className="card-of-expert">
      <div
        className="image"
        style={{ backgroundImage: `url(${props.img})` }}
      ></div>
      <div className="content">
        <div className="title">{props.title}</div>
        <div className="subtitle">{props.subtitle}</div>
      </div>
    </div>
  );
};

export default ExpertCard;
ExpertCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,

  

};