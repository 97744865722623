
import React, { useState, useEffect } from "react";
import BusinessForm from "./BusinessForm";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../../redux/actions/loginAction";
import { saveProject } from "../../../redux/actions/businessProjectAction";
import { initialProjectModel } from "../../../models/ProjectModel";
import { fetchOptions } from "../../../redux/actions/fetchOptionsAction";
import Layout from "../Layout";
import {useHistory} from "react-router-dom";
function PostProblem({
  project,
  saveProject,
  logout,
  options,
  fetchOptions,
  ...props
}) {
  const history = useHistory();
  if (project === null) {
    props.history.push("/business/dashboard/createprojects");
    project = initialProjectModel;
  }
  const post_project = {
    ...project,
    questions: [
      ...project.questions,
      {
        id: project.questions.length + 1 + "",
        value: "",
      },
    ],
    files_to_be_uploaded: [],
  };
  const [values, setValues] = useState(post_project);
  const [loading, setLoading] = useState(false);
  // debugger;
  useEffect(() => {
    if (options.domain_types.length === 0) {
      fetchOptions();
    }
  }, []);
  useEffect(() => {
    setValues({
      ...values,
      domains: options.domain_types
        .filter((option) => project.domains.includes(option.name))
        .map((option) => ({ value: option.id, label: option.name })),
        image:values.files[0]
       

    });
    
    console.log("these are values : ",values);
  }, [options]);


  // useEffect(()=>{
  //   console.log( "files", values.files[0]);
  //   setValues({
  //     ...values,
      
  //   });

  // },[])
  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     files:values.files.slice(1,-1)
  //   });
  // })
  const onSubmit = (e) => {
    e.preventDefault();
    var submitData = {
      ...values,
      questions: values.questions.slice(0, values.questions.length - 1),
      domains: values.domains.map((domain) => domain.label),
    };
    if(!submitData["image_to_be_uploaded"] || submitData["image_to_be_uploaded"].length == 0 ){
      alert("Please Upload a project image");
      return;
    }
    if (submitData.domains.length === 0) {
      alert("Please add domain types as well");
      return;
    }
    setLoading(true);
    console.log("data :",submitData);
    if(submitData["image_to_be_uploaded"] && submitData["image_to_be_uploaded"].length > 0){

      submitData["files_to_be_uploaded"] = [submitData["image_to_be_uploaded"][0]].concat(submitData["files_to_be_uploaded"]);
    }
    console.log("data :",submitData);
    saveProject(submitData)
      .then(() => props.history.push("/business/dashboard/projects"))
      .catch((err) => {
        console.log(err);
        const response = err.response;
        if (response.status === 403) {
          logout();
          alert("You are not logged in as business.Please login as Business");
        } else {
          alert("Some error encountered, Status code: " + response.status);
        }
      });
      history.push("/business/dashboard/projects");
      setLoading(false);
  };
  const onChange = async (name, value) => {
    if(name=="image_to_be_uploaded"){
      
      await setValues({
        ...values,
        [name]: [
          value.files[0]
        ],
      });
      console.log("value :",values, value.files[0]);
      return;
    }
    if (name !== "files_to_be_uploaded") {
      setValues({ ...values, [name]: value });
      return;
    }
    const size = 1000 * 1000 * 20;
    setValues({
      ...values,
      [name]: [
        ...values.files_to_be_uploaded,
        ...Object.values(value.files).filter((file) => file.size <= size),
      ],
    });
    console.log("these values :",values);
  };
  const delFile = (name) => {
    setValues({
      ...values,
      files_to_be_uploaded: values.files_to_be_uploaded.filter(
        (file) => file.name !== name
      ),
    });
  };
  const delUploadedFile = (id) => {
    setValues({
      ...values,
      files: values.files.filter((fileObj) => fileObj.id !== id),
    });
  };
  return (
    <Layout className="business-post-problem">
      <h3 className="text-center mt-4">Project / Business Problem</h3>
      <div className="d-flex justify-content-end mr-4">
        {/* <Link
          to="/business/dashboard/createprojects/invite-experts/"
          className="btn"
        >
          Invite Experts
        </Link> */}
      </div>
      <BusinessForm
        values={values}
        edit={project.id ? true : false}
        onChange={onChange}
        onSubmit={onSubmit}
        delFile={delFile}
        domain_types={options.domain_types.map((domain) => ({
          label: domain.name,
          value: domain.id,
        }))}
        loading = {loading}
        delUploadedFile={delUploadedFile}
      />
    </Layout>
  );
}

export function getProjectById(projects, id) {
  if (projects && projects.length > 0)
    return projects.find((project) => project.id === id) || null;
  else return null;
}

function matchStatetoProps(state, ownProps) {
  const options = state.options;
  const id = ownProps.match.params.id;
  console.log("projectId from url ==> ", id);
  let project = id ? getProjectById(state.projects, id) : initialProjectModel;
  // if (project)
  //   project = {
  //     ...project,
  //
  //   };
  console.log("projet froom redux state", project);
  return {
    project,
    options,
  };
}
const matchDispatchtoProps = { saveProject, logout, fetchOptions };
export default connect(matchStatetoProps, matchDispatchtoProps)(PostProblem);
PostProblem.propTypes = {
  project: PropTypes.object,
  history: PropTypes.object.isRequired,
  saveProject: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  fetchOptions: PropTypes.func.isRequired,
};
