export const formBusinessModel = {
  name: "",
  category: "",
  company_size: "",
  email: "",
  password: "",
};
export const formConsultantModel = {
  name: "",
  category: "",
  email: "",
  password: "",
};
