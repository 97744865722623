import React, { useEffect, useState } from "react";
import "./chat.css";
import Layout from "../Layout";
import ChatList from "./ChatList";
import jwt from "jwt-decode";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "../../../services/url";
const baseUrl = API_URL;

const Chat = () => {
  const [chatLists, setChatLists] = useState([]);
  const fetchChatList = async () => {
    try {
      const token = localStorage.getItem("consultant_token");

      // const token = localStorage.getItem("");

      const res = await axios.get(baseUrl + "/api/consultant/chat", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(res);
      console.log("lists", res.data);
      const filteredArr = res.data.data.reduce((acc, current) => {
        const x = acc.find(item => item.consultant_id === current.consultant_id && item.project_id === current.project_id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

   
      setChatLists(filteredArr);
      // setExperts(res.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    
    fetchChatList();
  }, []);

  // const b_id =  ;
  async function setChatDone (id){
    console.log("clicked")
    const token = localStorage.getItem("token");
    
    console.log("res 2 started",id);
    const res2 = await axios.post(baseUrl + "/api/business/invcons/update",
    {
      inv_con_id : id,
      val: 2
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    console.log("this is response from updating chat : ",res2);
    fetchChatList();
  }
  // console.log("b_id", jwt(localStorage.getItem("token")).data.business_id);

  return (
    <Layout>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="messaging">
            <div className="inbox_msg">
              <div className="inbox_people">
                <div className="headind_srch">
                  <div className="recent_heading">
                    <h5>Business</h5>
                  </div>
                </div>
                <div className="inbox_chat">
                  {chatLists.map((list, key) => (
                    <div key={key}>

                    <Link
                    onClick={()=> setChatDone(list.inv_con_id
                      )}
                      to={{
                        pathname: "/dashboard/chatscreen/" + list.project_id,
                        customobject: {
                          project_id: list.project_id,
                          business_id: list.business_id,
                          inv_con_id: list.inv_con_id,
                          new: list.new,
                          consultant_id: jwt(
                            localStorage.getItem("consultant_token")
                          ).data.consultant_id,
                        },
                      }}
                      
                    >
                      
                      <ChatList chatList={list} /> 
                    </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chat;
