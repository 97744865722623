import React from 'react'

export default function Security() {
  return (
    <div style={{margin:"32px"}}>
    <h1 className="aus__landing-card-title" style={{fontSize: "40px", marginBottom:"20px"}}>Security</h1>
    <h3 className="aus__landing-card-title" style={{fontSize: "24px"}}>

    DATA RETENTION AND ACCESS 
    </h3>
<p className="aus__landing-card-desc">
We will retain your personal data only for as long as necessary for the purposes it was retained, such as to enable you to use the Website and your products or to provide services to you. In some instances, we may retain data for longer periods in order to comply with applicable laws (including those regarding document retention), resolve disputes with any parties, and otherwise as necessary to allow us to conduct our business.
All personal data we retain will be subject to this Privacy Policy and our internal retention guidelines. We respect your control over your information and, upon request, we will seek to confirm your identity and whether we hold or are processing information that we have collected from you. You also have the right to amend or update inaccurate or incomplete personal information, request deletion of your personal information, or request that we no longer use it.
Under certain circumstances we will not be able to fulfill your request, such as if it interferes with our regulatory obligations, affects legal matters, we cannot verify your identity, or it involves disproportionate cost or effort, but in any event we will respond to your request within a reasonable timeframe and provide you with an explanation. 
</p>
<h3 className="aus__landing-card-title" style={{fontSize: "24px"}}>

HOW WE COLLECT PERSONAL DATA 
</h3>
<p className="aus__landing-card-desc">

We maintain appropriate administrative, technical, and physical safeguards designed to protect the personal data you provide against accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. That said, it is not possible to guarantee the security of information transmitted online, and you assume some risk with regard to the security of information you provide through any website, including this Site. 
</p>

    </div>
  )
}
