import { API_URL } from "../services/url";
// const baseUrl = process.env.API_URL;
const baseUrl = API_URL;

export async function fetchFile(file_id) {
  let fetcher = `${baseUrl}/api/project-file/${file_id}`;

  if(file_id.substring(0,4) == "http"){
  console.log("here");
  fetcher = file_id;
    
  }
  console.log("url : ",fetcher);
  // let url = baseUrl + `/api/file/projectfile/${file_id}`;
  window.open(fetcher);
}

export async function fetchChatFile(file_id) {
  let url = baseUrl + `/api/file/chatfile/${file_id}`;
  window.open(url);
}
